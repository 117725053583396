import { default as __nuxt_component_0 } from "/opt/build/repo/components/Common/InfoTooltip.vue";
import { default as __nuxt_component_1 } from "/opt/build/repo/components/Partials/FeeDiscounts/Tier.vue";
"use strict";
import { defineComponent as _defineComponent } from "vue";
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = { class: "table-responsive h-full" };
const _hoisted_2 = { class: "table" };
const _hoisted_3 = { class: "text-left" };
const _hoisted_4 = { class: "text-right" };
const _hoisted_5 = { class: "flex items-end justify-end" };
const _hoisted_6 = /* @__PURE__ */ _createElementVNode("th", { class: "text-center w-32" }, null, -1);
const _hoisted_7 = { class: "text-right" };
const _hoisted_8 = { class: "flex items-end justify-end" };
const _hoisted_9 = { class: "text-right" };
const _hoisted_10 = { class: "flex items-end justify-end" };
const _hoisted_11 = { class: "text-right" };
const _hoisted_12 = { class: "flex items-end justify-end" };
const _hoisted_13 = { key: 0 };
export default /* @__PURE__ */ _defineComponent({
  __name: "Index",
  setup(__props) {
    const exchangeStore = useExchangeStore();
    const tierLevelsWithZeroTierLevel = computed(
      () => exchangeStore.feeDiscountSchedule ? [
        {
          volume: "0",
          stakedAmount: "0",
          feePaidAmount: "0",
          makerDiscountRate: "0",
          takerDiscountRate: "0"
        },
        ...exchangeStore.feeDiscountSchedule.tierInfosList
      ] : []
    );
    return (_ctx, _cache) => {
      const _component_CommonInfoTooltip = __nuxt_component_0;
      const _component_PartialsFeeDiscountsTier = __nuxt_component_1;
      return _openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("table", _hoisted_2, [
          _createElementVNode("thead", null, [
            _createElementVNode("tr", null, [
              _createElementVNode("th", _hoisted_3, _toDisplayString(_ctx.$t("fee_discounts.tier")), 1),
              _createElementVNode("th", _hoisted_4, [
                _createElementVNode("div", _hoisted_5, [
                  _createElementVNode("span", null, _toDisplayString(_ctx.$t("fee_discounts.staked_amount")), 1),
                  _createVNode(_component_CommonInfoTooltip, {
                    class: "ml-2",
                    tooltip: _ctx.$t("fee_discounts.staked_amount_tooltip")
                  }, null, 8, ["tooltip"])
                ])
              ]),
              _hoisted_6,
              _createElementVNode("th", _hoisted_7, [
                _createElementVNode("div", _hoisted_8, [
                  _createElementVNode("span", null, _toDisplayString(_ctx.$t("fee_discounts.trading_volume")), 1),
                  _createVNode(_component_CommonInfoTooltip, {
                    class: "ml-2",
                    tooltip: _ctx.$t("fee_discounts.trading_volume_tooltip")
                  }, null, 8, ["tooltip"])
                ])
              ]),
              _createElementVNode("th", _hoisted_9, [
                _createElementVNode("div", _hoisted_10, [
                  _createElementVNode("span", null, _toDisplayString(_ctx.$t("fee_discounts.maker_rate_discount")), 1),
                  _createVNode(_component_CommonInfoTooltip, {
                    class: "ml-2",
                    tooltip: _ctx.$t("fee_discounts.maker_rate_discount_tooltip")
                  }, null, 8, ["tooltip"])
                ])
              ]),
              _createElementVNode("th", _hoisted_11, [
                _createElementVNode("div", _hoisted_12, [
                  _createElementVNode("span", null, _toDisplayString(_ctx.$t("fee_discounts.taker_rate_discount")), 1),
                  _createVNode(_component_CommonInfoTooltip, {
                    class: "ml-2",
                    tooltip: _ctx.$t("fee_discounts.taker_rate_discount_tooltip")
                  }, null, 8, ["tooltip"])
                ])
              ])
            ])
          ]),
          _unref(exchangeStore).feeDiscountSchedule ? (_openBlock(), _createElementBlock("tbody", _hoisted_13, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(tierLevelsWithZeroTierLevel), (tier, index) => {
              return _openBlock(), _createBlock(_component_PartialsFeeDiscountsTier, {
                key: `tier-${index}`,
                tier,
                index
              }, null, 8, ["tier", "index"]);
            }), 128))
          ])) : _createCommentVNode("", true)
        ])
      ]);
    };
  }
});
