"use strict";
import { defineComponent as _defineComponent } from "vue";
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = { class: "flex flex-col bg-gray-850 rounded-lg p-6 h-full" };
const _hoisted_2 = { class: "flex justify-start gap-6 lg:gap-8" };
const _hoisted_3 = { class: "flex flex-col" };
const _hoisted_4 = { class: "text-gray-500 uppercase tracking-wide text-xs mb-2 font-semibold whitespace-nowrap" };
const _hoisted_5 = { class: "uppercase text-xs lg:text-base text-gray-500 font-bold tracking-widest whitespace-nowrap" };
const _hoisted_6 = { class: "text-xl lg:text-2xl font-bold text-white tracking-normal font-mono" };
const _hoisted_7 = { class: "mt-4" };
const _hoisted_8 = { class: "text-xs text-gray-400" };
import { BigNumberInBase } from "@injectivelabs/utils";
import { ZERO_IN_BASE } from "@injectivelabs/sdk-ui-ts";
import { cosmosSdkDecToBigNumber } from "@injectivelabs/sdk-ts";
import { UI_DEFAULT_MIN_DISPLAY_DECIMALS } from "@/app/utils/constants";
export default /* @__PURE__ */ _defineComponent({
  __name: "StakedAmount",
  setup(__props) {
    const paramStore = useParamStore();
    const exchangeStore = useExchangeStore();
    const { valueToString: aprToFormat } = useBigNumberFormatter(
      computed(() => paramStore.apr.times(100)),
      {
        decimalPlaces: 2
      }
    );
    const stakedAmount = computed(() => {
      if (!exchangeStore.feeDiscountAccountInfo || !exchangeStore.feeDiscountAccountInfo.accountInfo) {
        return ZERO_IN_BASE;
      }
      return new BigNumberInBase(
        cosmosSdkDecToBigNumber(
          exchangeStore.feeDiscountAccountInfo.accountInfo.stakedAmount
        )
      );
    });
    const { valueToString: stakedAmountToFormat } = useBigNumberFormatter(
      stakedAmount,
      {
        decimalPlaces: UI_DEFAULT_MIN_DISPLAY_DECIMALS
      }
    );
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.$t("fee_discounts.my_staked_amount")), 1),
            _createElementVNode("span", _hoisted_5, [
              _createElementVNode("b", _hoisted_6, _toDisplayString(_unref(stakedAmountToFormat)), 1),
              _createTextVNode(" INJ ")
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.$t("fee_discounts.current_apr")) + ": \u2248 " + _toDisplayString(_unref(aprToFormat)) + "% ", 1)
        ])
      ]);
    };
  }
});
