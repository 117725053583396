"use strict";
import { defineComponent as _defineComponent } from "vue";
import { unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode } from "vue";
const _hoisted_1 = { class: "h-8 text-left font-mono" };
const _hoisted_2 = { class: "flex items-center gap-4" };
const _hoisted_3 = {
  key: 0,
  class: "bg-blue-500 w-2 h-2 ml-2 rounded-full"
};
const _hoisted_4 = {
  key: 1,
  class: "w-2 h-2 ml-2"
};
const _hoisted_5 = { class: "h-8 text-right font-mono" };
const _hoisted_6 = /* @__PURE__ */ _createElementVNode("span", { class: "text-2xs text-gray-500" }, " INJ ", -1);
const _hoisted_7 = { class: "h-8 text-right font-mono" };
const _hoisted_8 = { class: "text-gray-500 uppercase text-2xs tracking-wider" };
const _hoisted_9 = { class: "h-8 text-right font-mono" };
const _hoisted_10 = /* @__PURE__ */ _createElementVNode("span", { class: "text-2xs text-gray-500" }, " USD ", -1);
const _hoisted_11 = { class: "h-8 text-right font-mono" };
const _hoisted_12 = { class: "h-8 text-right font-mono" };
import { BigNumberInBase, BigNumberInWei } from "@injectivelabs/utils";
import { ZERO_IN_BASE } from "@injectivelabs/sdk-ui-ts";
import {
  getExactDecimalsFromNumber,
  cosmosSdkDecToBigNumber
} from "@injectivelabs/sdk-ts";
import { UI_MINIMAL_AMOUNT, USDT_DECIMALS } from "@/app/utils/constants";
export default /* @__PURE__ */ _defineComponent({
  __name: "Tier",
  props: {
    tier: {
      required: true,
      type: Object
    },
    index: {
      required: true,
      type: Number
    }
  },
  setup(__props) {
    const props = __props;
    const walletStore = useWalletStore();
    const exchangeStore = useExchangeStore();
    const isUserTierLevel = computed(() => {
      if (!walletStore.isUserWalletConnected) {
        return false;
      }
      if (!exchangeStore.feeDiscountAccountInfo) {
        return false;
      }
      return new BigNumberInBase(exchangeStore.feeDiscountAccountInfo.tierLevel).eq(
        props.index
      );
    });
    const stakedAmount = computed(
      () => new BigNumberInBase(cosmosSdkDecToBigNumber(props.tier.stakedAmount || 0))
    );
    const volume = computed(
      () => new BigNumberInWei(cosmosSdkDecToBigNumber(props.tier.volume || 0)).toBase(
        USDT_DECIMALS
      )
    );
    const makerFeeDiscount = computed(() => {
      if (!props.tier.makerDiscountRate) {
        return ZERO_IN_BASE;
      }
      const makerDiscountRate = new BigNumberInWei(props.tier.makerDiscountRate).times(100).toBase();
      if (makerDiscountRate.lte(UI_MINIMAL_AMOUNT)) {
        return ZERO_IN_BASE;
      }
      return makerDiscountRate;
    });
    const takerFeeDiscount = computed(() => {
      if (!props.tier.takerDiscountRate) {
        return ZERO_IN_BASE;
      }
      const takerDiscountRate = new BigNumberInWei(props.tier.takerDiscountRate).times(100).toBase();
      if (takerDiscountRate.lte(UI_MINIMAL_AMOUNT)) {
        return ZERO_IN_BASE;
      }
      return takerDiscountRate;
    });
    const { valueToString: makerFeeDiscountToFormat } = useBigNumberFormatter(
      makerFeeDiscount,
      {
        decimalPlaces: getExactDecimalsFromNumber(makerFeeDiscount.value.toNumber())
      }
    );
    const { valueToString: stakedAmountToFormat } = useBigNumberFormatter(
      stakedAmount,
      {
        decimalPlaces: 0
      }
    );
    const { valueToString: volumeToFormat } = useBigNumberFormatter(volume, {
      decimalPlaces: 0
    });
    const { valueToString: takerFeeDiscountToFormat } = useBigNumberFormatter(
      takerFeeDiscount,
      {
        decimalPlaces: getExactDecimalsFromNumber(takerFeeDiscount.value.toNumber())
      }
    );
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("tr", null, [
        _createElementVNode("td", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _unref(isUserTierLevel) ? (_openBlock(), _createElementBlock("div", _hoisted_3)) : (_openBlock(), _createElementBlock("div", _hoisted_4)),
            _createElementVNode("span", null, "#" + _toDisplayString(__props.index), 1)
          ])
        ]),
        _createElementVNode("td", _hoisted_5, [
          _createTextVNode(" \u2265 " + _toDisplayString(_unref(stakedAmountToFormat)) + " ", 1),
          _hoisted_6
        ]),
        _createElementVNode("td", _hoisted_7, [
          _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.$t("and")), 1)
        ]),
        _createElementVNode("td", _hoisted_9, [
          _createTextVNode(" \u2265 " + _toDisplayString(_unref(volumeToFormat)) + " ", 1),
          _hoisted_10
        ]),
        _createElementVNode("td", _hoisted_11, _toDisplayString(_unref(makerFeeDiscountToFormat)) + "%", 1),
        _createElementVNode("td", _hoisted_12, _toDisplayString(_unref(takerFeeDiscountToFormat)) + "%", 1)
      ]);
    };
  }
});
