import { default as __nuxt_component_0 } from "/opt/build/repo/components/Partials/FeeDiscounts/Overview.vue";
import { default as __nuxt_component_1 } from "/opt/build/repo/components/Partials/FeeDiscounts/Index.vue";
import { default as __nuxt_component_2 } from "/opt/build/repo/components/App/Panel/Index.vue";
import { default as __nuxt_component_3 } from "/opt/build/repo/components/App/HocLoading.vue";
"use strict";
import { defineComponent as _defineComponent } from "vue";
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = (n) => (_pushScopeId("data-v-267e553c"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "fee-discounts min-h-screen-excluding-header-and-footer" };
const _hoisted_2 = { class: "container" };
const _hoisted_3 = { class: "w-full mx-auto xl:w-4/5" };
const _hoisted_4 = { class: "mt-6" };
const _hoisted_5 = { class: "text-xl font-bold text-gray-200" };
const _hoisted_6 = { class: "mt-6" };
const _hoisted_7 = { class: "text-sm font-normal mb-2" };
const _hoisted_8 = { class: "text-sm text-blue-500 font-normal" };
import { Status, StatusType } from "@injectivelabs/utils";
export default /* @__PURE__ */ _defineComponent({
  __name: "fee-discounts",
  setup(__props) {
    const exchangeStore = useExchangeStore();
    const paramStore = useParamStore();
    const { $onError } = useNuxtApp();
    const status = reactive(new Status(StatusType.Loading));
    onMounted(() => {
      Promise.all([
        exchangeStore.fetchParams(),
        exchangeStore.fetchFeeDiscountSchedule(),
        exchangeStore.fetchFeeDiscountAccountInfo(),
        paramStore.fetchAprParams()
      ]).then(() => {
      }).catch($onError).finally(() => {
        status.setIdle();
      });
    });
    onBeforeUnmount(() => {
      exchangeStore.reset();
    });
    return (_ctx, _cache) => {
      const _component_PartialsFeeDiscountsOverview = __nuxt_component_0;
      const _component_PartialsFeeDiscounts = __nuxt_component_1;
      const _component_AppPanel = __nuxt_component_2;
      const _component_AppHocLoading = __nuxt_component_3;
      return _openBlock(), _createBlock(_component_AppHocLoading, { status: _unref(status) }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, [
                  _createElementVNode("h3", _hoisted_5, _toDisplayString(_ctx.$t("fee_discounts.page_title")), 1),
                  _createElementVNode("div", _hoisted_6, [
                    _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.$t("fee_discounts.page_description")), 1),
                    _createElementVNode("p", _hoisted_8, _toDisplayString(_ctx.$t("fee_discounts.page_description_warning")), 1)
                  ]),
                  _createVNode(_component_PartialsFeeDiscountsOverview, { class: "my-8" }),
                  _createVNode(_component_AppPanel, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_PartialsFeeDiscounts)
                    ]),
                    _: 1
                  })
                ])
              ])
            ])
          ])
        ]),
        _: 1
      }, 8, ["status"]);
    };
  }
});
