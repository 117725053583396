import { default as __nuxt_component_0 } from "/opt/build/repo/components/Common/Card/Index.vue";
"use strict";
import { defineComponent as _defineComponent } from "vue";
import { unref as _unref, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode } from "vue";
const _hoisted_1 = { class: "flex flex-col" };
const _hoisted_2 = {
  key: 0,
  class: "flex justify-between items-center"
};
const _hoisted_3 = { class: "text-xl font-bold text-gray-200" };
const _hoisted_4 = { class: "v-panel-content" };
export default /* @__PURE__ */ _defineComponent({
  __name: "Index",
  props: {
    dense: Boolean,
    title: {
      default: "",
      type: String
    },
    portalName: {
      default: "",
      type: String
    },
    cardWrapperClass: {
      default: "",
      type: String
    }
  },
  setup(__props) {
    const slots = useSlots();
    return (_ctx, _cache) => {
      const _component_CommonCard = __nuxt_component_0;
      return _openBlock(), _createElementBlock("div", _hoisted_1, [
        __props.title || _unref(slots)["title"] || _unref(slots)["title-context"] ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("h3", _hoisted_3, [
            _renderSlot(_ctx.$slots, "title", {}, () => [
              _createTextVNode(_toDisplayString(__props.title), 1)
            ]),
            _renderSlot(_ctx.$slots, "subtitle")
          ]),
          _renderSlot(_ctx.$slots, "title-context")
        ])) : _createCommentVNode("", true),
        _renderSlot(_ctx.$slots, "context"),
        _createVNode(_component_CommonCard, {
          class: _normalizeClass(["relative flex-1", [__props.cardWrapperClass]]),
          lg: !__props.dense
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_4, [
              _renderSlot(_ctx.$slots, "default", { ref: "content" })
            ])
          ]),
          _: 3
        }, 8, ["lg", "class"])
      ]);
    };
  }
});
