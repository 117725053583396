"use strict";
import { defineComponent as _defineComponent } from "vue";
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = { class: "flex flex-col bg-gray-850 rounded-lg p-6 h-full" };
const _hoisted_2 = { class: "flex justify-start gap-6 lg:gap-8" };
const _hoisted_3 = { class: "flex flex-col border-r border-gray-500 pr-6 lg:pr-8" };
const _hoisted_4 = { class: "text-gray-500 uppercase tracking-wide text-xs mb-2 font-semibold whitespace-nowrap" };
const _hoisted_5 = { class: "uppercase text-xl lg:text-2xl font-bold tracking-normal text-blue-500" };
const _hoisted_6 = { class: "flex flex-col" };
const _hoisted_7 = { class: "text-gray-500 uppercase tracking-wide text-xs mb-2 font-semibold whitespace-nowrap" };
const _hoisted_8 = { class: "uppercase text-xs lg:text-base text-gray-500 font-bold tracking-widest whitespace-nowrap" };
const _hoisted_9 = { class: "text-xl lg:text-2xl font-bold text-white tracking-normal font-mono" };
const _hoisted_10 = { class: "flex flex-col" };
const _hoisted_11 = { class: "text-gray-500 uppercase tracking-wide text-xs mb-2 font-semibold whitespace-nowrap" };
const _hoisted_12 = { class: "uppercase text-xs lg:text-base text-gray-500 font-bold tracking-widest whitespace-nowrap" };
const _hoisted_13 = { class: "text-xl lg:text-2xl font-bold text-white tracking-normal font-mono" };
const _hoisted_14 = { class: "mt-4" };
const _hoisted_15 = {
  key: 0,
  class: "text-xs text-gray-400"
};
const _hoisted_16 = {
  key: 1,
  class: "text-xs text-gray-400"
};
import { BigNumberInBase, BigNumberInWei } from "@injectivelabs/utils";
import { format } from "date-fns";
import { UI_DEFAULT_MIN_DISPLAY_DECIMALS } from "@/app/utils/constants";
export default /* @__PURE__ */ _defineComponent({
  __name: "TierInfo",
  setup(__props) {
    const exchangeStore = useExchangeStore();
    const feeDiscountAccountInfo = computed(() => {
      return exchangeStore.feeDiscountAccountInfo;
    });
    const tierLevel = computed(
      () => {
        var _a;
        return new BigNumberInBase(((_a = feeDiscountAccountInfo.value) == null ? void 0 : _a.tierLevel) || 0).toNumber();
      }
    );
    const makerFeeDiscount = computed(() => {
      if (!feeDiscountAccountInfo.value) {
        return "";
      }
      if (!feeDiscountAccountInfo.value.accountInfo) {
        return "";
      }
      return new BigNumberInWei(
        feeDiscountAccountInfo.value.accountInfo.makerDiscountRate
      ).toBase().times(100).toFormat(UI_DEFAULT_MIN_DISPLAY_DECIMALS);
    });
    const takerFeeDiscount = computed(() => {
      if (!feeDiscountAccountInfo.value) {
        return "";
      }
      if (!feeDiscountAccountInfo.value.accountInfo) {
        return "";
      }
      return new BigNumberInWei(
        feeDiscountAccountInfo.value.accountInfo.takerDiscountRate
      ).toBase().times(100).toFormat(UI_DEFAULT_MIN_DISPLAY_DECIMALS);
    });
    const lastUpdateTimestamp = computed(() => {
      if (!feeDiscountAccountInfo.value || !feeDiscountAccountInfo.value.accountTtl) {
        return void 0;
      }
      return format(
        Number(feeDiscountAccountInfo.value.accountTtl.ttlTimestamp) * 1e3,
        "yyyy-MM-dd HH:mm:ss (zzz)"
      );
    });
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.$t("fee_discounts.my_tier")), 1),
            _createElementVNode("span", _hoisted_5, " #" + _toDisplayString(_unref(tierLevel)), 1)
          ]),
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.$t("fee_discounts.maker")), 1),
            _createElementVNode("span", _hoisted_8, [
              _createElementVNode("b", _hoisted_9, _toDisplayString(_unref(makerFeeDiscount)) + "% ", 1),
              _createTextVNode(" " + _toDisplayString(_ctx.$t("fee_discounts.off")), 1)
            ])
          ]),
          _createElementVNode("div", _hoisted_10, [
            _createElementVNode("span", _hoisted_11, _toDisplayString(_ctx.$t("fee_discounts.taker")), 1),
            _createElementVNode("span", _hoisted_12, [
              _createElementVNode("b", _hoisted_13, _toDisplayString(_unref(takerFeeDiscount)) + "% ", 1),
              _createTextVNode(" " + _toDisplayString(_ctx.$t("fee_discounts.off")), 1)
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_14, [
          _unref(lastUpdateTimestamp) ? (_openBlock(), _createElementBlock("span", _hoisted_15, _toDisplayString(_ctx.$t("fee_discounts.update_daily")) + ". " + _toDisplayString(_ctx.$t("fee_discounts.last_updated_at")) + " " + _toDisplayString(_unref(lastUpdateTimestamp)), 1)) : (_openBlock(), _createElementBlock("span", _hoisted_16, "\u2014"))
        ])
      ]);
    };
  }
});
