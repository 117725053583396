"use strict";
import { defineComponent as _defineComponent } from "vue";
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = { class: "flex flex-col bg-gray-850 rounded-lg p-6 h-full" };
const _hoisted_2 = { class: "flex justify-start gap-6 lg:gap-8" };
const _hoisted_3 = { class: "flex flex-col" };
const _hoisted_4 = { class: "text-gray-500 uppercase tracking-wide text-xs mb-2 font-semibold whitespace-nowrap" };
const _hoisted_5 = { class: "uppercase text-xs lg:text-base text-gray-500 font-bold tracking-widest whitespace-nowrap" };
const _hoisted_6 = { class: "text-xl lg:text-2xl font-bold text-white tracking-normal font-mono" };
const _hoisted_7 = { class: "mt-4" };
const _hoisted_8 = { class: "text-xs text-gray-400" };
import { ZERO_IN_BASE } from "@injectivelabs/sdk-ui-ts";
import { BigNumberInBase, BigNumberInWei } from "@injectivelabs/utils";
import { cosmosSdkDecToBigNumber } from "@injectivelabs/sdk-ts";
import { intervalToDuration } from "date-fns";
import {
  UI_MINIMAL_ABBREVIATION_FLOOR,
  USDT_DECIMALS
} from "@/app/utils/constants";
export default /* @__PURE__ */ _defineComponent({
  __name: "TradingVolume",
  setup(__props) {
    const exchangeStore = useExchangeStore();
    const volume = computed(() => {
      if (!exchangeStore.feeDiscountAccountInfo || !exchangeStore.feeDiscountAccountInfo.accountInfo || !exchangeStore.feeDiscountAccountInfo.accountInfo.volume) {
        return ZERO_IN_BASE;
      }
      const volume2 = new BigNumberInBase(
        cosmosSdkDecToBigNumber(
          exchangeStore.feeDiscountAccountInfo.accountInfo.volume
        )
      );
      return new BigNumberInWei(volume2).toBase(USDT_DECIMALS);
    });
    const shouldAbbreviateVolume = computed(
      () => volume.value.gte(UI_MINIMAL_ABBREVIATION_FLOOR)
    );
    const { valueToString: volumeToFormat } = useBigNumberFormatter(volume, {
      decimalPlaces: shouldAbbreviateVolume.value ? 0 : 2,
      abbreviationFloor: shouldAbbreviateVolume.value ? UI_MINIMAL_ABBREVIATION_FLOOR : void 0
    });
    const daysPassed = computed(() => {
      if (!exchangeStore.feeDiscountSchedule) {
        return "0";
      }
      const totalInSeconds = exchangeStore.feeDiscountSchedule.bucketDuration * exchangeStore.feeDiscountSchedule.bucketCount;
      const { days } = intervalToDuration({
        start: 0,
        end: totalInSeconds * 1e3
      });
      if (!days) {
        return "0";
      }
      return days.toString();
    });
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.$t("fee_discounts.my_trading_volume")), 1),
            _createElementVNode("span", _hoisted_5, [
              _createElementVNode("b", _hoisted_6, _toDisplayString(_unref(volumeToFormat)), 1),
              _createTextVNode(" USD ")
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.$t("fee_discounts.in_past_days", { days: _unref(daysPassed) })), 1)
        ])
      ]);
    };
  }
});
