import { default as __nuxt_component_0 } from "/opt/build/repo/node_modules/@injectivelabs/ui-shared/lib/components/Icon.vue";
import { default as __nuxt_component_1 } from "/opt/build/repo/node_modules/@injectivelabs/ui-shared/lib/components/HoverMenu.vue";
"use strict";
import { defineComponent as _defineComponent } from "vue";
import { renderSlot as _renderSlot, unref as _unref, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export default /* @__PURE__ */ _defineComponent({
  __name: "InfoTooltip",
  props: {
    lg: Boolean,
    tooltip: {
      type: String,
      default: ""
    }
  },
  setup(__props) {
    const props = __props;
    const sizeClasses = computed(() => {
      if (props.lg) {
        return "w-4 h-4 min-w-4";
      }
      return "w-3 h-3 min-w-3";
    });
    return (_ctx, _cache) => {
      const _component_BaseIcon = __nuxt_component_0;
      const _component_BaseHoverMenu = __nuxt_component_1;
      return _openBlock(), _createBlock(_component_BaseHoverMenu, { "popper-class": "tooltip" }, {
        default: _withCtx(() => [
          _renderSlot(_ctx.$slots, "default", {}, () => [
            _createVNode(_component_BaseIcon, {
              name: "circle-info",
              class: _normalizeClass(["text-gray-500", [_unref(sizeClasses)]])
            }, null, 8, ["class"])
          ])
        ]),
        content: _withCtx(() => [
          _createTextVNode(_toDisplayString(__props.tooltip), 1)
        ]),
        _: 3
      });
    };
  }
});
