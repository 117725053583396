import { default as __nuxt_component_0 } from "/opt/build/repo/components/Partials/FeeDiscounts/TierInfo.vue";
import { default as __nuxt_component_1 } from "/opt/build/repo/components/Partials/FeeDiscounts/StakedAmount.vue";
import { default as __nuxt_component_2 } from "/opt/build/repo/components/Partials/FeeDiscounts/TradingVolume.vue";
import { default as __nuxt_component_3 } from "/opt/build/repo/components/App/HorizontalScrollView/Index.vue";
import { default as __nuxt_component_4 } from "/opt/build/repo/components/App/HocLoading.vue";
"use strict";
import { defineComponent as _defineComponent } from "vue";
import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue";
const _hoisted_1 = {
  key: 0,
  class: "my-8"
};
import { Status, StatusType } from "@injectivelabs/utils";
export default /* @__PURE__ */ _defineComponent({
  __name: "Overview",
  setup(__props) {
    const walletStore = useWalletStore();
    const exchangeStore = useExchangeStore();
    const { $onError } = useNuxtApp();
    const status = reactive(new Status(StatusType.Loading));
    onWalletConnected(() => {
      status.setLoading();
      Promise.all([exchangeStore.fetchFeeDiscountAccountInfo()]).catch($onError).finally(() => {
        status.setIdle();
      });
    });
    return (_ctx, _cache) => {
      const _component_PartialsFeeDiscountsTierInfo = __nuxt_component_0;
      const _component_PartialsFeeDiscountsStakedAmount = __nuxt_component_1;
      const _component_PartialsFeeDiscountsTradingVolume = __nuxt_component_2;
      const _component_AppHorizontalScrollView = __nuxt_component_3;
      const _component_AppHocLoading = __nuxt_component_4;
      return _openBlock(), _createBlock(_component_AppHocLoading, { status: _unref(status) }, {
        default: _withCtx(() => [
          _unref(walletStore).isUserWalletConnected ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode(_component_AppHorizontalScrollView, null, {
              default: _withCtx(() => [
                _createVNode(_component_PartialsFeeDiscountsTierInfo, { class: "flex-0-full md:col-span-6" }),
                _createVNode(_component_PartialsFeeDiscountsStakedAmount, { class: "flex-0-full md:col-span-3" }),
                _createVNode(_component_PartialsFeeDiscountsTradingVolume, { class: "flex-0-full md:col-span-3" })
              ]),
              _: 1
            })
          ])) : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["status"]);
    };
  }
});
