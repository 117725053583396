"use strict";
import { defineComponent as _defineComponent } from "vue";
import { unref as _unref, renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
export default /* @__PURE__ */ _defineComponent({
  __name: "Index",
  props: {
    lg: Boolean,
    md: Boolean,
    tight: Boolean,
    noPadding: Boolean,
    noTopBorderRadius: Boolean,
    bgColor: {
      type: String,
      default: "bg-gray-900"
    }
  },
  setup(__props) {
    const props = __props;
    const borderRadiusClass = computed(() => {
      if (props.noTopBorderRadius) {
        return "rounded-b-xl";
      }
      return "rounded-xl";
    });
    const padding = computed(() => {
      if (props.noPadding) {
        return "p-0";
      }
      return {
        "p-4 lg:p-6": props.lg && !props.tight,
        "p-4": props.md && !props.tight,
        "p-2 lg:p-3": !props.md && !props.lg && !props.tight
      };
    });
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", {
        class: _normalizeClass([_unref(borderRadiusClass), __props.bgColor, _unref(padding)])
      }, [
        _renderSlot(_ctx.$slots, "default")
      ], 2);
    };
  }
});
